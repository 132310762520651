import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import left from '../images/handleLeft.png';
import right from '../images/handleRight.png';
import meshimg from '../images/mesh.png';
import Handle from '../images/slideHandle.png';
import Fan from '../images/fan.png';
ReactSession.setStoreType("localStorage");

const Navbar = () => {
  
  const userId = ReactSession.get("userId");
  const urlParams = new URLSearchParams(window.location.search);
  const quoteIds = ReactSession.get("quoteId");
  const [quoteData, setQuoteData] = useState(null);
  const userType = ReactSession.get("userType");
  const userName = ReactSession.get("userName");
  const userImage = ReactSession.get("Image");
  const userLimit = ReactSession.get("limit");
  const usersub = ReactSession.get("subUsers");


  const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);
  const [SidebarActive, setSidebarActive] = useState(false);
  const Empid = ReactSession.get("empid");

  const [bottomFrame, setBottomFrame] = useState('yes');
  const [mesh, setMesh] = useState('no');
  const [panelHeights, setpanelHeight] = useState(0); 
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 2 : 4);
  const [divider, setDevider] = useState('Vertical');
  const [switchs, setSwitch] = useState('on');
  const [mCalc, setMcalc] = useState(1);

  
//console.log(userLimit, usersub);

let users='';
    if (userType === '3') {
      users =  Empid ;
    } else {
      users = userId ;
    }
    const user = { "userId": users };


    useEffect(() => {
      const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
        setScreen(window.innerWidth > 700 ? 2 : 4);
      };
  
      window.addEventListener('resize', updateDeviceWidth);
  
      return () => {
        window.removeEventListener('resize', updateDeviceWidth);
      };
    }, []);
  
  

  const handleSidebarClick = () => {
    setIsMobileSidebarActive(!isMobileSidebarActive);
  };

  const savedTheme = ReactSession.get("theme") || 'light';
  const [theme, setTheme] = useState(savedTheme);

  const savedSideBar = ReactSession.get("sidebar") || 'pc-sidebar';
  const [sidebar, setSidebar] = useState(savedSideBar);

  const sibeBar = () => {
    setSidebar(prevSidebar => {
      const newSidebar = prevSidebar === 'pc-sidebar' ? 'pc-sidebar-hide' : 'pc-sidebar';
      ReactSession.set("sidebar", newSidebar); // Save the new theme to the session
      return newSidebar;
    });
  };

  useEffect(() => {
    document.body.setAttribute('data-pc-theme', theme);
    ReactSession.set("theme", theme); // Save the theme to the session
    ReactSession.set("siebar", sidebar);
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', theme === 'dark' ? '#131920' : '#fff');
    }
  }, [theme, sidebar]);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'dark' ? 'light' : 'dark';
      ReactSession.set("theme", newTheme); // Save the new theme to the session
      return newTheme;
    });
  };


  const fetchData = async () => {
    try {
      const response = await axios.post(`https://upvcapi.sninfoserv.com/all-quotes/`, user);
      setQuoteData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
  console.log(filteredQuotes);


  const createGrill = (boxHeight, newHeights, mCalc) => {

    const boxes = Array.from({ length: newHeights!== 0 ? (boxHeight-newHeights)*1.9 : boxHeight*2 }).map((_, index) => (
    <div
      key={index}
      style={{
        position: 'relative',
        width: '100%',
        height: '0.16vw',
        border: '1px solid #333',
        background: '#fff',
        marginTop: `calc((${boxHeight*screen/mCalc+'vw'} - 25px) / ${boxHeight*2.6}) `,
      }}
    ></div>
    ));

    return boxes;

  };


    return (
<>
  <nav className={`${savedSideBar} ${isMobileSidebarActive ? 'mob-sidebar-active pc-sidebar' : 'pc-sidebar'}`}>
  <div className={`${isMobileSidebarActive ? 'pc-menu-overlay' : ''}`} >
  {isMobileSidebarActive && (
    <button onClick={handleSidebarClick} style={{float:'right', top: '10px', right: '10px', background: 'transparent', border: 'none', color: theme === 'dark' ? '#fff': '#000', fontSize: '24px', margin: '20px 20px 0px 0px', fontWeight: '600', zIndex: '999'}}>x</button>
  )}
    </div>
        <div class="navbar-wrapper">
           
            <div class="navbar-content">
                <div class="card pc-user-card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0"><img src={`https://upvcapi.sninfoserv.com/user/images/${userImage}`} alt={userName} class="user-avtar wid-45 rounded-circle" /></div>
                            <div class="flex-grow-1 ms-3 me-2">
                                <h6 class="mb-0">{userName}</h6><small>{userType === '1' ? 'Super Admin' : userType === '2' ? 'Admin' : 'User' }</small></div>
                            <a class="btn btn-icon btn-link-secondary avtar" data-bs-toggle="collapse" href="#pc_sidebar_userlink">
                                <svg class="pc-icon">
                                    <use href="#custom-sort-outline"></use>
                                </svg>
                            </a>
                        </div>
                        <div class="collapse pc-user-links" id="pc_sidebar_userlink">
                            <div class="pt-3">
							  <a href="/account"><i class="ti ti-user"></i> <span>My Account</span> </a>
                {userType === '2' && usersub <= userLimit &&(

                <a href="/addemployee"><i class="ti ti-user-plus"></i> <span>Add Employee</span> </a>
              )}
              {userType != '3' && (
                <a href="/settings"><i class="ti ti-settings"></i> <span>Settings</span> </a>
              )}
							  
							  <a href="/logout"><i class="ti ti-power"></i> <span>Logout</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="pc-navbar">
        <li class="pc-item pc-caption">
          <label>Navigation</label>
        </li>
        <li class="pc-item">
          <a href="/dashboard" class="pc-link">
            <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-status-up"></use>
              </svg>
            </span>
            <span class="pc-mtext">Dashboard</span>
          </a>
        
        </li>

        <li class="pc-item">
          <a href="/create-quotation" class="pc-link">
            <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-document"></use>
              </svg>
            </span>
            <span class="pc-mtext">New Quotation</span>
          </a>
        </li>

        {userType === '1' && (
          <>
          <li class="pc-item">
            <a href="/default" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-document-filter"></use>
              </svg>
              </span>
              <span class="pc-mtext">Add Default Profiles</span>
            </a>
          </li> 

          <li class="pc-item">
            <a href="/add-user" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              </span>
              <span class="pc-mtext">Add User</span>
            </a>
          </li>

          <li class="pc-item">
            <a href="/user" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              </span>
              <span class="pc-mtext">View User</span>
            </a>
          </li>
          </>
        )}


          <li class="pc-item">
            <a href="/quote" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              
              </span>
              <span class="pc-mtext">View Quotes</span>
            </a>
          </li>

          <li class="pc-item">
            <a href="/client" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              
              </span>
              <span class="pc-mtext">Clients</span>
            </a>
          </li>
          {userType === '2' && (
          <li class="pc-item">
            <a href="/employees" class="pc-link">
              <span class="pc-micon">
              <svg class="pc-icon">
                <use href="#custom-user"></use>
              </svg>
              
              </span>
              <span class="pc-mtext">Employees</span>
            </a>
          </li>
          )}
      </ul>
            </div>
        </div>
    </nav>
   
    <header class="pc-header">
        <div class="header-wrapper">
            <div class="me-auto pc-mob-drp">
                <ul class="list-unstyled">
                    <li class="pc-h-item pc-sidebar-collapse"><a onClick={sibeBar} class="pc-head-link ms-0" id="sidebar-hide"><i class="ti ti-menu-2"></i></a></li>
                    <li class="pc-h-item pc-sidebar-popup"><a onClick={handleSidebarClick} class="pc-head-link ms-0" id="mobile-collapse"><i class="ti ti-menu-2"></i></a></li>
                   
                </ul>
            </div>
         
            <div class="ms-auto">
                <ul class="list-unstyled">
                    <li class="dropdown pc-h-item">
                        <a class="pc-head-link dropdown-toggle arrow-none me-0" onClick={toggleTheme}>
                            <svg class="pc-icon">
                            { theme === 'dark' ?( <use href="#custom-sun-1"></use>):( <use href="#custom-moon"></use>)}
                            </svg>
                        </a>
                    </li>
                   
                    <li class="pc-h-item">
                        <a href="#" class="pc-head-link me-0" data-bs-toggle="offcanvas" data-bs-target="#announcement" aria-controls="announcement">
                            <svg class="pc-icon">
                                <use href="#custom-flash"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>

<div class="offcanvas pc-announcement-offcanvas offcanvas-end" tabindex="-1" id="announcement" aria-labelledby="announcementLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="announcementLabel">Quotes</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
     {/* <p class="text-span">{quoteIds}</p> */}
    {quoteIds !== "" ?(<>
      {filteredQuotes?.map((quote, index) => (
     
     <div class="card mb-3" key={index}>
       {quote.quoteDetails.map((detail, detailIndex) => (
       <div class="card-body" key={detailIndex}>
         <div class="align-items-center d-flex flex-wrap gap-2 mb-3">
         <h5 style={{ marginLeft: '10px' }}>{detail.quoteData.item} X {detail.quoteData.qty}</h5>
          
         </div>
       
         {detail.quoteData.item === 'CasementDoor' && (
           <div class="col-md-12 col-xxl-12 col-xl-12">
           <div class="card" style={{background:'#fff'}}>
             <div class="card-body mb-5">
             
             <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
 
 <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw',  height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333', borderBottom: bottomFrame === 'no' ? '1px solid #33333350' : '1px solid #333'  }}>
 {[...Array(detail.quoteData.numFrames)].map((_, index) => (
 <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height: bottomFrame === 'no' ? `calc(${detail.quoteData.boxHeight*screen+'vw'} - 6px)` : `calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`, } }> 
 {detail.quoteData.newHeights !== 0 ? (
 <>
 
 { detail.quoteData.boxPosition === 'Top' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightblue', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}
 
 <div style={{ display: 'flex' }}>
   {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
     <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 23px)` : `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
      
            
      
      
       { index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
            <img
            src={left}
            width='15'
            style={{
              float: 'left',
              marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
              marginLeft: '2px',
              width: screen === 2 ? '13px' : '8px'
            }}
            
            alt="Left Handle"
          />
        ) : (
          <img
            src={right}
            width='15'
            style={{
              float: 'right',
              marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
              marginRight: '2px',
              width: screen === 2 ? '13px' : '8px'
            }}
            
            alt="Right Handle"
          />
        ) )}
 
 
             { detail.quoteData.mesh === 'yes' ? (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - ${bottomFrame === 'no' ? '76px' : '80px' })`}} />
               ):(<></>)
             }
       
       { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
 
     </div>
 
   ))}
   
 </div>
 { detail.quoteData.boxPosition === 'Bottom' ? (
     <div></div>
   ) : (
     [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}
 
 </>
 ) : (
 <>
 <div style={{ display: 'flex' }}>
 {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
   panelHeights === 0 ? (
   <div key={index} style={{ flex: '1', height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight) * screen}vw - 18px )` : `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
    
    
    
     { index === 0 &&  detail.quoteData.boxHandle[index] === 'left' && (
     
             <img
               src={right}
               width='15'
               style={{
                 float: 'right',
                 marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                 marginRight: '2px',
                 width: screen === 2 ? '13px' : '8px'
               }}
               
               alt="Left Handle"
             />
             
 
           )}  
           { index === 1 &&  detail.quoteData.boxHandle[index] === 'right' &&(
          
             <img
               src={left}
               width='15'
               style={{
                 float: 'left',
                 marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                 marginLeft: '2px',
                 width: screen === 2 ? '13px' : '8px'
               }}
               
               alt="Right Handle"
             />
             
           )}
 
 
            { detail.quoteData.mesh === 'yes' ? (
               <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - ${bottomFrame === 'no' ? '71px' : '75px' })`}} />
               ):(<></>)
             }
            
             
            { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
          
   </div>
   ) : (
     <div key={index} style={{  height: panelHeights*screen+'vw', minWidth:`calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: 'lightblue', border: '1px solid #333',margin:'5px 5px 5px'}}>
   
     <div key={index} style={{ height: bottomFrame === 'no' ? `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}vw - 18px )` : `calc(${(detail.quoteData.boxHeight-panelHeights) * screen}vw - 30px )`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue',  border: '1px solid #333',marginTop: `calc(${panelHeights * screen}vw + 5px )` }}>
       {  index === 0 && ( detail.quoteData.boxHandle[index] === 'left' ? (
         <>
               <img
                 src={left}
                 width='15'
                 style={{
                   float: 'left',
                   marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                   marginLeft: '2px',
                   width: screen === 2 ? '13px' : '8px'
                 }}
                 
                 alt="Left Handle"
               />
               
               </>
             ) : (
               <>
               <img
                 src={right}
                 width='15'
                 style={{
                   float: 'right',
                   marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/15))+'vw',
                   marginRight: '2px',
                   width: screen === 2 ? '13px' : '8px'
                 }}
                 
                 alt="Right Handle"
               />
               
               </>
             ) )}
              { detail.quoteData.mesh === 'yes' ? (
               <img src={meshimg} style={{width:'50px', float:'left',  bottom:'0px', marginTop:`calc(${(detail.quoteData.boxHeight) * screen}vw - 75px )` }} />
              ):(<></>)}
               { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
               
             
            
     </div>
     </div>
   )
 ))}
 </div> 
    
 </>
 )}
 </div>
  ))}
 </div>
 
   
 </div>
 
             </div>
           </div>
         </div>
         )}
          {detail.quoteData.item === 'CasementWindow' && (
          <div class="col-md-12 col-xxl-12 col-xl-12">
          <div class="card" style={{background:'#fff'}}>
            <div class="card-body mb-5">
            
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
 
 <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw'}}>
 {[...Array(detail.quoteData.numFrames)].map((_, index) => (
 <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
 {detail.quoteData.newHeights !== 0 ? (
 <>
 
 { detail.quoteData.boxPosition === 'Top' ? (
    <div></div>
  ) : (
    [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}
 
 <div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
     
            
 
     
     
      { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
            <img
              src={left}
              style={{
                float: 'left',
                marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}vw - 15px`,
                marginLeft: '2px',
                width: screen === 2 ? '13px' : '8px'
              }}
              
              alt="Left Handle"
            />
          ) : (
            <img
              src={right}
              style={{
                float: 'right',
                marginTop: `calc(${(((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)/2)}vw - 15px`,
                marginRight: '2px',
                width: screen === 2 ? '13px' : '8px'
              }}
              
              alt="Right Handle"
            />
            
          ) )}
 
 {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
                 <img src={meshimg} style={{width: screen === 3 ? '40px' : '20px', float:'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px': detail.quoteData.boxHandle[index] === 'left' ? '-15px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen/mCalc)}vw - 72px)` : `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen/mCalc)}vw - 50px)`}} />
                 )
               }
          
          { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
    </div>
 
  ))}
  
 </div>
 { detail.quoteData.boxPosition === 'Bottom' ? (
    <div></div>
  ) : (
    [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
 <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
 ))
 )}
 
 
 </>
 ) : (
 <>
 <div style={{ display: 'flex' }}>
 {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
  <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
    
            
    
            
    
    { detail.quoteData.boxNames[index] === 'Shutter' && ( detail.quoteData.boxHandle[index] === 'left' ? (
            <img
              src={left}
              width='15px'
              style={{
                float: 'left',
                marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}vw - 15px`,
                marginLeft: '2px',
                width: screen === 2 ? '13px' : '8px'
              }}
              
              alt="Left Handle"
            />
          ) : (
            <img
              src={right}
              width='15px'
              style={{
                float: 'right',
                marginTop: `calc(${((detail.quoteData.boxHeight*screen)/2)}vw - 15px`,
                marginRight: '2px',
                width: screen === 2 ? '13px' : '8px',
                verticalAlign: 'bottom'
              }}
              
              alt="Right Handle"
            />
          ) )}
 
 
 {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && (
                 <img src={meshimg} style={{width: screen === 3 ? '40px' : '20px', float:'left', marginLeft: screen === 3 ? detail.quoteData.boxHandle[index] === 'left' ? '-15px' : '0px': detail.quoteData.boxHandle[index] === 'left' ? '-15px' : 'px', marginTop: screen === 3 ? `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen/mCalc)}vw - 72px)` : `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen/mCalc)}vw - 45px)`}} />
                 )
               }
           
           { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
            
         
  </div>
 ))}
 </div> 
   
 </>
 )}
 </div>
 ))}
 </div>
 
  
 </div>
 
 
              
            </div>
          </div>
        </div>
         )}
          {detail.quoteData.item === 'SlidingDoor' && (
            <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
              
              <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
 
 <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw'}}>
 {[...Array(detail.quoteData.numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
  {detail.quoteData.newHeights !== 0 ? (
 <>
  
  { detail.quoteData.boxPosition === 'Top' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
  <div style={{ display: 'flex' }}>
    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
      
      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
      
             
 
 
       <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: '-6px',
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
 
    <div style={{
        height:'7px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 31px)`,
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
       
       
            { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
              <img
                src={Handle}
                height = {deviceWidth > 700 ? '10px' : '20px'}
                style={{
                  float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                  marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'vw',
                  marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                  marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                  width: 'auto'
                }}
                
                alt="Handle"
              />
            )}
 
            {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 80px)`}} />
                )
            }
            
            { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
 
      </div>
 
    ))}
    
  </div>
  { detail.quoteData.boxPosition === 'Bottom' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
 
 </>
 ) : (
 <>
 <div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
    <>
    
    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 23px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.panelType === 1 ? 'lightgray' : detail.quoteData.panelType === 2 ? 'lightyellow' : 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
            
            
 
 
    <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: '-6px',
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
 
    <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`,
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
         
         
           { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
              <img
                src={Handle}
                height = {deviceWidth > 700 ? '10px' : '20px'}
                style={{
                  float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'vw',
                  marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                  marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                  width: 'auto'
                }}
                
                alt="Handle"
              />
            )}
             
            {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 75px)`}} />
                )
            }
 
 { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
           
    </div>
    </>
  ))}
 </div> 
     
 </>
  )}
  </div>
   ))}
 </div>
 
    
 </div>
 
                
              </div>
            </div>
          </div>
         )}
         {detail.quoteData.item === 'SlidingWindow' && (
            <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
              
              <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
 
 <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw'}}>
 {[...Array(detail.quoteData.numFrames)].map((_, index) => (
  <div style={{float:'left', margin:'5px', border:'1px solid #333',width:`calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 12px)`, height:`calc(${detail.quoteData.boxHeight*screen+'vw'} - 12px)`}}> 
  {detail.quoteData.newHeights !== 0 ? (
 <>
  
  { detail.quoteData.boxPosition === 'Top' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
  <div style={{ display: 'flex' }}>
    {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
      <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight - detail.quoteData.newHeights) * screen}vw - 30px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginTop: detail.quoteData.boxPosition === 'Top' ? '5px' : '0px', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333' }}>
      
            
      
       <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: '-6px',
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
 
    <div style={{
        height:'7px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: `calc(${(detail.quoteData.boxHeight-detail.quoteData.newHeights) * screen}vw - 31px)`,
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
       
       
            { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
              <img
                src={Handle}
                height = {deviceWidth > 700 ? '10px' : '20px'}
                style={{
                  float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                  marginTop: ((detail.quoteData.boxHeight-detail.quoteData.newHeights) * ((screen/2)-(screen/8)))+'vw',
                  marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                  marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                  width: 'auto'
                }}
                
                alt="Handle"
              />
            )}
            
            {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 82px)`}} />
                )
            }
 
 { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
 
      </div>
 
    ))}
    
  </div>
  { detail.quoteData.boxPosition === 'Bottom' ? (
      <div></div>
    ) : (
      [...Array(detail.quoteData.numFirstInnerBoxes)].map((_, index) => (
  <div style={{width: `calc(${detail.quoteData.boxWidth*screen/detail.quoteData.numFrames+'vw'} - 23px)`, height:`calc(${(detail.quoteData.newHeights * screen)}vw )`, background: 'lightgreen', margin:'5px', border:'1px solid #333'}}></div>
  ))
  )}
 
 
 </>
 ) : (
 <>
 <div style={{ display: 'flex' }}>
  {[...Array(detail.quoteData.numSecondInnerBoxes)].map((_, index) => (
    <>
    
    <div key={index} style={{ flex: '1', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`, width: `calc(${detail.quoteData.newWidths[index] * screen}vw - 5px - 19px)`, backgroundColor: detail.quoteData.boxColors[index] || 'lightblue', marginRight: index === detail.quoteData.numSecondInnerBoxes - 1 ? '5px' : '2.5px', marginLeft: index === 0 ? '5px' : '2.5px', border: '1px solid #333',marginTop:'5px' }}>
         
 
             
 
 
    <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: '-6px',
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
 
    <div style={{
        height:'5px',
        width: '1px',
        float: 'right',
        right: '0px',
        marginTop: `calc(${(detail.quoteData.boxHeight) * screen}vw - 24px)`,
        marginRight: '-1px',
        background: index === (detail.quoteData.numSecondInnerBoxes-1) ? 'transparent' : 'black',
     }}></div>
         
         
           { detail.quoteData.boxNames[index] !== 'Shutter' ? (<></>) : (
              <img
                src={Handle}
                height = {deviceWidth > 700 ? '10px' : '20px'}
                style={{
                  float: detail.quoteData.boxHandle[index] === 'left' ? 'left' : 'right',
                  marginTop: (detail.quoteData.boxHeight) * ((screen/2)-(screen/13))+'vw',
                  marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '2px' : '0px',
                  marginRight: detail.quoteData.boxHandle[index] === 'left' ? '0px' : '4px',
                  width: 'auto'
                }}
                
                alt="Handle"
              />
            )}
              
            {detail.quoteData.mesh === 'yes' && detail.quoteData.boxNames[index] === 'Shutter' && index % 2 === 0 && (
                <img src={meshimg} style={{width:'50px', float:'left', marginLeft: detail.quoteData.boxHandle[index] === 'left' ? '-6px' : '0px', marginTop: `calc(${((detail.quoteData.boxHeight-detail.quoteData.newHeights)*screen)}vw - 75px)`}} />
                )
            }
            { detail.quoteData.grill === 'yes' && createGrill(detail.quoteData.boxHeight, detail.quoteData.newHeights, mCalc)}
    </div>
    </>
  ))}
 </div> 
 </>
  )}
  </div>
   ))}
 </div>
 
 
    
 </div>
 
                
              </div>
            </div>
          </div>
         )}
   
   {detail.quoteData.item === 'Ventilator' && (
            <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
               <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
           {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth > 1 && (  
            <>
 
            <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
           
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Vertical' && switchs === 'on' && (
                    <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                      <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}
 
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
 
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}
 
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                    <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                      <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}
 
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                  </div>
                )}
 
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight) * screen}vw - 20px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(detail.quoteData.boxHeight-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}
 
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Top' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                  </div>
                )}
                
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '0px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginLeft: '4px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}
 
                {detail.quoteData.fanTop === 'Bottom' && detail.quoteData.fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${(detail.quoteData.boxHeight-.85) * screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/detail.quoteData.boxHeight)*screen}%, lightblue ${(10/detail.quoteData.boxHeight)*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginRight: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(detail.quoteData.boxWidth-.85) * screen}vw - 26px)`, marginRight: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}
 
            </div>
                
            </>
            
          )} 
 
          {detail.quoteData.boxHeight == 1 && detail.quoteData.boxWidth > 1 && (
            <>
           {detail.quoteData.switchFixed === 'on' && (  
            
              <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
            )}
 
            {detail.quoteData.switchFixed === 'off' && (  
            
            <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
              <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight)*screen}vw - 22px)`, width: `calc(${(detail.quoteData.boxWidth- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
              </div>
            </div>
          )}
            </>
          )}
 
          {detail.quoteData.boxHeight > 1 && detail.quoteData.boxWidth == 1 && (
            <>
             {detail.quoteData.switchFixed === 'on' && (  
              <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}vw - 20px)`, marginRight: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
             
             {detail.quoteData.switchFixed === 'off' && (
              <div style={{float:'left', width: detail.quoteData.boxWidth*screen+'vw', height:detail.quoteData.boxHeight*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${detail.quoteData.boxHeight * screen}vw - 10px)`, width: `calc(${detail.quoteData.boxWidth * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(detail.quoteData.boxHeight-.75)*screen}vw - 25px)`, width: `calc(${(detail.quoteData.boxWidth)*screen}vw - 20px)`, marginRight: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/detail.quoteData.boxHeight)*screen}%, lightblue ${(6/detail.quoteData.boxHeight)*screen}%)`, border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
            </>
          )}
         </div>
        
             </div>
            </div>
          </div>
         )}
         
       </div>
       ))}
     </div>
      ))}
    
 
    <div class="card mb-3">
       <div class="card-body">
        
       <div class="row">
           <div class="col-12">
             <div class="d-grid"  style={{display:'flex', alignItems:'center', justifyContent:'center'}}
               ><a
                 class="btn btn-outline-secondary"
                 href="/select"
                 
                 ><i class="ti ti-plus"></i> Add</a ></div>
           </div>
         </div>
       </div>
     </div>
    </>):(<>
      <div class="card mb-3">
       <div class="card-body">
        
       <div class="row">
           <div class="col-12">
             <div class="d-grid"  style={{display:'flex', alignItems:'center', justifyContent:'center'}}
               ><a
                 class="btn btn-outline-secondary"
                 href="/create-quotation"
                 ><i class="ti ti-plus"></i> Quote</a ></div>
           </div>
         </div>
       </div>
     </div>
    </>)}
   
    
  </div>
</div>

</>

);
};

export default Navbar;