import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import Fan from '../images/fan.png';
import numeral from 'numeral';
import './box.css';
ReactSession.setStoreType("localStorage");

 function Editquotees() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const urlParams = new URLSearchParams(window.location.search);
  const quoteId = ReactSession.get("quoteId");
  const quoteIds = urlParams.get('id');
  const Empid = ReactSession.get("empid");
 // console.log('id',quoteIds);
  const navigate = useNavigate();
  const indexPath = '/';
  const selectPath = '/select';
  const item = 'Ventilator';
  let users='';
  if (userType === '3') {
    users =  Empid ;
  } else {
    users = userId ;
  }
  const user = { "userId": users };

  useEffect(() => {
    if (userId === 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  const [quoteData, setquoteData] = useState(null);
  const [state, setState]=useState(null);
  const [boxWidth, setBoxWidth] = useState(); 
  const [boxHeight, setBoxHeight] = useState(); 
  const [fanTop, setfanTop] = useState('');
  const [fanLeft, setfanLeft] = useState('');
  const [divider, setDevider] = useState('');
  const [switchs, setSwitch] = useState('');
  const [switchFixed, setFixed] = useState('');
  const [fanType, setfanType] = useState(1);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [screen, setScreen] = useState(deviceWidth > 700 ? 5 : 10);
  const [profileDetails, setProfileData] = useState(true);
  const [qty, setQty] = useState();
  const [costData, setCostData] = useState(true);
  const [customProfile, setCustomProfile] = useState();
  const arrayvalue = urlParams.get('arrayValues');
  const [calcBtn, setCalcBtn] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  //console.log("url",arrayvalue);
  const [series, setSeries] = useState(null);
  const [rowid, setRowid]=useState('');
 
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(window.innerWidth);
      setScreen(window.innerWidth > 700 ? 4 : 8);
    };

    window.addEventListener('resize', updateDeviceWidth);

    return () => {
      window.removeEventListener('resize', updateDeviceWidth);
    };
  }, []);



  
  const fetcholdData = async () => {
    try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', user);
        setquoteData(response.data);
     console.log("String",response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
        fetcholdData();
        }, []);
        
        const filteredQuotes = quoteData && quoteData.data.filter(quote => quote.quoteId === quoteIds);
       // console.log(filteredQuotes);
      
       console.log(filteredQuotes);

    
       useEffect(()=>{
        if(filteredQuotes){
          setState(filteredQuotes[0].quoteDetails[arrayvalue].quoteData)
          
         setRowid(filteredQuotes[0].quoteDetails[arrayvalue].rowId);
        }
        
        
        }
        ,[filteredQuotes])
        //console.log(state);



useEffect(() => {
  
  const boxWidth=state?.boxWidth;
  const boxHeight=state?.boxHeight;
 const fanType=state?.fanType;
 const switchFixed=state?.switchFixed;
 const switchs= state?.switchs;
 const divider= state?.divider;
 const fanLeft= state?.fanLeft;
 const fanTop=state?.fanTop;
 const qty=state?.qty;
 const marginCost=state?.data.marginCost;
 const marketingCost=state?.data.marketingCost;
 const labourFCost=state?.data.labourFcost;
 const labourSCost=state?.data.labourScost;
 const transportCost=state?.data.transportCost;
 const glassCost=state?.data.glass;
 const series=state?.data.series;
 const steelFramCost=state?.data.steelFrame;
 const steelMullionCost=state?.data.steelMullion;
 const wastageCost=state?.data.wastage;
 const screwtCost=state?.data.screwsCost;
 const meshgasketCost=state?.data.gasket;
 const LouverBladesCost=state?.data.LouverBlades;


  


  setBoxWidth(Number(boxWidth));
  setBoxHeight(Number(boxHeight));
  setSwitch(switchs);
  setDevider(divider);
  setfanLeft(fanLeft);
  setfanTop(fanTop);
 setQty(qty);
 setfanType(fanType);
 setFixed(switchFixed);
 setMarginCost(marginCost);
 setMarketingCost(marketingCost);
 setlabourFCost(labourFCost);
 setlabourSCost(labourSCost);
 setTransportCost(transportCost);
 setGlassCost(glassCost);
 setSeries(series);
 setsteelFramDef(steelFramCost);
 setsteelMullionDef(steelMullionCost);
 setwastageCost(wastageCost);
 setscrewDef(screwtCost);
 setmeshgasketDef(meshgasketCost);
 setLouverBladesDef(LouverBladesCost);

 
  
  
}, [state]);
//console.log(state);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/profiles/', user);
        setProfileData(response.data);
      //  console.log("profile:", response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);


 


  
  
  useEffect(() => {
    const fetchcostData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/costs/', user);
        setCostData(response.data.data);
       // console.log("cost", response.data);
      } catch (error) {
      } 
    };

    fetchcostData();
  }, []);
  

  const handleQty = (event) => {
    if(event === 'plus'){
      setQty(qty + 1);
      
    }else{
      const minValue = 2;
      if (qty < minValue) {
        setQty(qty);
      } else {
        setQty(qty - 1);
      }
    }
  };

  const handleQtyChange = (event) => {
    const newValue = event.target.value;
    const minValue = 1;
    if (newValue < minValue) {
      setQty(minValue);
    } else {
      setQty(newValue);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/profiles/', user);
        setProfileData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);



  const handleWidthChange = (event) => {
    const newValue = event.target.value;
    const minValue = 2;
    if (newValue < minValue) {
      setBoxWidth(minValue); 
    } else {
      setBoxWidth(newValue);
    }
   
  };

  const handleHeightChange = (event) => {
    const newValue = event.target.value;
    const minValue = 2;
    if (newValue < minValue) {
      setBoxHeight(minValue);
    } else {
      setBoxHeight(newValue);
    }
  };

  const handleWidth = (event) => {
    if(event === 'plus'){
      setBoxWidth(Number(boxWidth) + 1);
      
    }else{
      const minValue = 1;
      if (Number(boxWidth) <= minValue) {
        setBoxWidth(Number(boxWidth));
      } else {
        setBoxWidth(Number(boxWidth) - 1);
      }
    }
  };

  const handleHeight = (event) => {
    if(event === 'plus'){
      setBoxHeight(Number(boxHeight) + 1);
    }else{
      const minValue = 1;
      if (Number(boxHeight) <= minValue) {
        setBoxHeight(Number(boxHeight));
      } else {
        setBoxHeight(Number(boxHeight)-1);
      }
    }
  };
  

  const changefanTop = () => {
    setfanTop(fanTop === 'Top' ? 'Bottom' : 'Top');
  };

  const changefanLeft = () => {
    setfanLeft(fanLeft === 'Left' ? 'Right' : 'Left');
  };

  const changeDevider = () => {
    setDevider(divider === 'Vertical' ? 'Horizontal' : 'Vertical');
  };

  const changeSwitch = () => {
    setSwitch(switchs === 'on' ? 'off' : 'on');
  };

  const changeFixed= () => {
    setFixed(switchFixed === 'on' ? 'off' : 'on');
  };

  const handleButtonClick = (buttonName) => {
    setfanType(buttonName);
  };
  
  const handleProfileChange = (event) => {
    setCustomProfile(event.target.value);
    setSelectedFrameSeries(null);
    setSelectedFrameId(null);
    setSelectedMullionSeries(null);
    setSelectedMullionId(null);
   
  };

  //----------prifile Section-----------//
  
  const findIndexByProfileName = (profileName) => {
    return profileDetails?.data?.findIndex(item => item.profileName === profileName);
  };

  useEffect(() => {
    if (customProfile === null || customProfile === undefined) {
      if (profileDetails && state?.selectedProfile) {
        const profileIndex = findIndexByProfileName(state.selectedProfile, profileDetails);
        setCustomProfile(profileIndex);
      }
    }
  }, [profileDetails, state?.selectedProfile]);
  console.log(customProfile);

const profileData = profileDetails?.data?.[customProfile]?.profileData || [];
const selectedProfile = profileDetails?.data?.[customProfile]?.profileName;
 


const findSeriesAndIdByName = (searchName) => {
  for (const series of profileData) {
    if (Array.isArray(series.items)) {
        for (const item of series.items) {
            if (item.id === searchName) {
                return { series: series.series, id: item.id };
            }
        }
    } else {
        console.error('series.items is not an array');
    }
}

return { series: null };
};

  const frameData =  findSeriesAndIdByName(state?.data.frame.id);
  const mullionData =  findSeriesAndIdByName(state?.data.mullion.id);
  const beedingData =  findSeriesAndIdByName(state?.data.beeding.id);
  const beedingLData =  findSeriesAndIdByName(state?.data.Lbeeding.id);

  const [selectedFrameSeries, setSelectedFrameSeries] = useState(null);
  const [selectedFrameId, setSelectedFrameId] = useState(null);

  const handleFrameSeriesChange = (event) => {
    const seriesId = event.target.value;
    
    setSelectedFrameSeries(seriesId);
    setSelectedFrameId(null);
    console.log(seriesId);
  };


  const handleFrameIdChange = (event) => {
    const id = event.target.value;
    setSelectedFrameId(id);
    console.log(id);
  };
  
  useEffect(() =>{
    if(selectedFrameSeries === null){
    setSelectedFrameSeries(frameData.series);
    setSelectedFrameId(frameData.id);
    }
  });

  const seriesDataFrame = profileData && profileData.find(serieses => serieses.series === selectedFrameSeries);
  const selectedFramePrice = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).price : '0';
  const selectedFrameLength = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).length : '0';
  const selectedFrameWeight = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).weight : '0';
  const selectedFrameName = selectedFrameId ? seriesDataFrame.items.find(item => item.id === selectedFrameId).name : 'Loading';

console.log(selectedFrameLength);
console.log(selectedFrameWeight);
console.log(selectedFramePrice);

  const [selectedMullionSeries, setSelectedMullionSeries] = useState(null);
  const [selectedMullionId, setSelectedMullionId] = useState(null);
  
  const handleMullionSeriesChange = (event) => {
    const seriesId = event.target.value;
    
    setSelectedMullionSeries(seriesId);
    setSelectedMullionId(null); 
  };

  const handleMullionIdChange = (event) => {
    const id = event.target.value;
    setSelectedMullionId(id);
  };

  useEffect(() =>{
    if(selectedMullionSeries === null){
    setSelectedMullionSeries(mullionData.series);
    setSelectedMullionId(mullionData.id); 
    }
  });

  const seriesDataMullion = profileData && profileData.find(serieses => serieses.series === selectedMullionSeries);
  const selectedMullionPrice = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).price : '0';
  const selectedMullionLength = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).length : '0';
  const selectedMullionWeight = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).weight :'0';
  const selectedMullionName = selectedMullionId ? seriesDataMullion.items.find(item => item.id === selectedMullionId).name : "Loading";
  
  const [selectedBeedingSeries, setSelectedBeedingSeries] = useState(null);
   const [selectedBeedingId, setSelectedBeedingId] = useState(null);

  const handleBeedingSeriesChange = (event) => {
    const seriesId = event.target.value;
    setSelectedBeedingSeries(seriesId);
    setSelectedBeedingId(null); 
  };

  const handleBeedingIdChange = (event) => {
    const id = event.target.value;
    setSelectedBeedingId(id);
  };

  useEffect(() =>{
    if(selectedBeedingSeries === null){
      setSelectedBeedingSeries(beedingData.series);
      setSelectedBeedingId(beedingData.id); 
    }
  });

  const seriesDataBeeding = profileData && profileData.find(serieses => serieses.series === selectedBeedingSeries);
  const selectedBeedingPrice = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).price : '0';
  const selectedBeedingLength = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).length : '0';
  const selectedBeedingWeight = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).weight : '0';
  const selectedBeedingName = selectedBeedingId ? seriesDataBeeding.items.find(item => item.id === selectedBeedingId).name : 'Loading';
  

  const [selectedLBeedingSeries, setSelectedLBeedingSeries] = useState(null);
  const [selectedLBeedingId, setSelectedLBeedingId] = useState(null);
  
  const handleLBeedingSeriesChange = (event) => {
    const seriesId = event.target.value;
    setSelectedLBeedingSeries(seriesId);
    setSelectedLBeedingId(null); 
  };

  const handleLBeedingIdChange = (event) => {
   const id = event.target.value;
   setSelectedLBeedingId(id);
 };

 useEffect(() =>{
  if(selectedLBeedingSeries === null){
    setSelectedLBeedingSeries(beedingLData.series);
    setSelectedLBeedingId(beedingLData.id); 
  }
});

 const seriesDataLBeeding = profileData && profileData.find(serieses => serieses.series === selectedLBeedingSeries);
 const selectedLBeedingPrice = selectedLBeedingId ? seriesDataLBeeding.items.find(item => item.id === selectedLBeedingId).price : '0';
 const selectedLBeedingLength = selectedLBeedingId ? seriesDataLBeeding.items.find(item => item.id === selectedLBeedingId).length : '0';
 const selectedLBeedingWeight = selectedLBeedingId ? seriesDataLBeeding.items.find(item => item.id === selectedLBeedingId).weight : '0';
 const selectedLBeedingName = selectedLBeedingId ? seriesDataLBeeding.items.find(item => item.id === selectedLBeedingId).name : 'Loading';


  const [marginCost, setMarginCost] = useState(null);
  const margin = costData?.costData?.margin;
  const marginChange = (event) => {
    const cost = event.target.value;
    setMarginCost(cost);
  };

  const [marketingCost, setMarketingCost] = useState(null);
  const marketing= costData?.costData?.marketing;
  const marketingChange = (event) => {
    const cost = event.target.value;
    setMarketingCost(cost);
  };

  const [labourFCost, setlabourFCost] = useState(null);
  const labourF = costData?.costData?.labourFactory;
  const labourFChange = (event) => {
    const cost = event.target.value;
    setlabourFCost(cost);
  };

  const [labourSCost, setlabourSCost] = useState(null);
  const labourS = costData?.costData?.labourSite;
  const labourSChange = (event) => {
    const cost = event.target.value;
    setlabourSCost(cost);
  };

  const [transportCost, setTransportCost] = useState(null);
  const transport = costData?.costData?.transport;
  const transportChange = (event) => {
    const cost = event.target.value;
    setTransportCost(cost);
  };


  const [glassCost, setGlassCost] = useState(null);
  const glasses = costData?.costData?.glass;
  const glassChange = (event) => {
    const cost = event.target.value;
    setGlassCost(cost);
  };
  // console.log(glassCost);
 console.log(glasses);


  
  const [wastageCost, setwastageCost] = useState(null);
  const wasteValues = costData?.costData?.wastage;
  const wasteChange = (event) => {
    const cost = event.target.value;
    setwastageCost(cost);
  };
  console.log(wastageCost);
 

  const [meshgasketCost, setmeshgasketDef] = useState(null);
  const meshgasketValues = costData?.costData?.meshGasket;
  const meshgasketChange = (event) => {
    const cost = event.target.value;
    setmeshgasketDef(cost);
  };
  console.log(meshgasketCost);


  const [meshCost, setmeshDef] = useState(null);
  const meshValues = costData?.costData?.mesh;
  const meshChange = (event) => {
    const cost = event.target.value;
    setmeshDef(cost);
  };

  const [screwtCost, setscrewDef] = useState(null);
  const screwValues = costData?.costData?.screws;
  const screwChange = (event) => {
    const cost = event.target.value;
    setscrewDef(cost);
  };
  
  const [steelFramCost, setsteelFramDef] = useState(null);
  const steelValues = costData?.costData?.Steel;
  const steelframeChange = (event) => {
    const cost = event.target.value;
    setsteelFramDef(cost);
  };


  const [steelMullionCost, setsteelMullionDef] = useState(null);
  const steelMullionValues = costData?.costData?.Steel;
  const steelMullionChange = (event) => {
    const cost = event.target.value;
    setsteelMullionDef(cost);
  };
  

  const [LouverBladesCost, setLouverBladesDef] = useState(null);
  const LouverBladesValues = costData?.costData?.blades;
  const LouverBladesChange = (event) => {
    const cost = event.target.value;
    setLouverBladesDef(cost);
  };
  console.log(LouverBladesCost);



  
 const marginDef = costData && costData.costData && costData.costData.margin['Margin-3'];
   const marketingDef = costData && costData.costData && costData.costData.marketing['Marketing-3'];
   const labourFactoryDef = costData && costData.costData && costData.costData.labourFactory['LabourFactory-3'];
   const labourSiteDef = costData && costData.costData && costData.costData.labourSite['LabourSite-3'];
   const transportDef = costData && costData.costData && costData.costData.transport['Transport-3'];
   const glassDef = costData && costData.costData && costData.costData.glass['5mm'];
   const steelFramDef = costData && costData.costData && costData.costData.Steel['Frame'];
  const steelShutterDef = costData && costData.costData && costData.costData.Steel['Shutter'];
  const steelMullionDef = costData && costData.costData && costData.costData.Steel['Mullion'];
  const wasteDef = costData && costData.costData && costData.costData.wastage['Wastage-3'];
  const bladesDef = costData && costData.costData && costData.costData.blades['L-Blades-3'];
  const meshGasketDef = costData && costData.costData && costData.costData.meshGasket['Gasket-3'];
  const meshDef = costData && costData.costData && costData.costData.mesh['Mesh-3'];
  const screwDef = costData && costData.costData && costData.costData.screws['Screw-3'];

  

  const addDef = () => {
    setMarginCost(marginDef);
    setMarketingCost(marketingDef);
    setlabourFCost(labourFactoryDef);
    setlabourSCost(labourSiteDef);
    setTransportCost(transportDef);
    setGlassCost(glassDef);
    setsteelFramDef(steelFramDef);
    
    setsteelMullionDef(steelMullionDef);
    setwastageCost(wasteDef);
    setLouverBladesDef(bladesDef);
    setscrewDef(screwDef);
    setmeshgasketDef(meshGasketDef);
    setmeshDef(meshDef);
    
};

useEffect(() => {
    addDef();
}, []);

 
  
const data = {
  mullion:{
    length: selectedMullionLength*3.28084,
    weight: selectedMullionWeight,
    amount: selectedMullionPrice,
    name: selectedMullionName,
    id:selectedMullionId,
    
  },
  frame:{
    length: selectedFrameLength*3.28084 ,
    weight: selectedFrameWeight,
    amount:selectedFramePrice,
    name : selectedFrameName,
    id:selectedFrameId,
  },
  shutter:{
    length: null,
    weight:null,
    amount:null,
    name : null
  },
  beeding:{
    length: selectedBeedingLength*3.28084,
    weight: selectedBeedingWeight,
    amount: selectedBeedingPrice,
    name : selectedBeedingName,
    id:selectedBeedingId
  },
  Lbeeding:{
    length: selectedLBeedingLength*3.28084,
    weight: selectedLBeedingWeight,
    amount: selectedLBeedingPrice,
    name : selectedLBeedingName,
    id:selectedLBeedingId
  },
  cupler:{
    length: null,
    weight:null,
    amount:null,
    name : null
  },
  meshFrame:{
    length: null,
    weight:null,
    amount:null,
    name : null
  },
  
  fan:{
    type1:'280',
    type2:'230',
    type3:'200',
  },
  gasket:{
    length:'1',
    weight:'1',
    amount: meshgasketCost?.amount
  },
  screwsCost: screwtCost,
  wastage:wastageCost,
  glass: glassCost,
  LouverBlades: LouverBladesCost,
  marketingCost: marketingCost,
    transportCost: transportCost,
    labourFcost: labourFCost,
    labourScost: labourSCost,
    marginCost: marginCost,
    steelFrame: steelFramCost,
    steelMullion: steelMullionCost,
}
console.log(data.frame.length);
 
console.log(data.frame.weight);
   
  let fanLength;

  if (fanType === 1) {
    fanLength = 1;
  } else if (fanType === 2) {
    fanLength = 0.75;
  } else if (fanType === 3) {
    fanLength = 0.5833;
  } else {
    fanLength = 0; 
  }

  const Mullion = () => {
    if(Number(boxHeight) === 1){
      return  Number(boxHeight);
    }else if(Number(boxWidth)=== 1){
      return  Number(boxWidth);
    }else{
      if(divider === 'Vertical'){
        return  Number(boxHeight) + fanLength;
      }else{
        return  Number(boxWidth) + fanLength;
    }
  }
  };

  const Frame = () => {
    return ( Number(boxHeight) + Number(boxWidth) )*2;
  };

  const LouverBlades = () => {
    if(Number(boxHeight) === 1 && Number(boxWidth) > 1){
      if(switchFixed === 'on'){
        return 0;
      }else{
        return (Number(boxHeight)*8);
      }
    }else if(Number(boxHeight) > 1 && Number(boxWidth) === 1){
      if(switchFixed === 'on'){
        return 0;
      }else{
        return ((Number(boxHeight)-1)*8);
      }
    }else{
      if (divider === 'Vertical' && switchs === 'on'){
        return (8 * Number(boxHeight));
      }
      if (divider === 'Vertical' && switchs === 'off'){
        return (8 * (Number(boxHeight)-1));
      }
      if (divider === 'Horizontal' && switchs === 'on'){
        return (8 * (Number(boxHeight) -1));
      }
      if (divider === 'Horizontal' && switchs === 'off'){
        return (8 * (Number(boxHeight)-1));
      }
    }
  };

  const Glass = () => {
    if(Number(boxHeight) === 1 && Number(boxWidth) > 1){
      if(switchFixed === 'on'){
        return ((Number(boxHeight))*Number(boxWidth)-1);
      }else{
        return (Number(boxHeight)*4)*(95*0.00328084)*(Number(boxWidth)-1);
      }
    }else if(Number(boxHeight) > 1 && Number(boxWidth) === 1){
      if(switchFixed === 'on'){
        return ((Number(boxHeight)-1)*Number(boxWidth));
     }else{
        return ((Number(boxHeight)-1)*4)*(95*0.00328084)*(Number(boxWidth));
     }
    }
    else{
      if (divider === 'Vertical' && switchs ==='on'){
        return ((Number(boxHeight)-1)*(Number(boxWidth)-1))+((Number(boxHeight)*4)*(95*0.00328084))*(Number(boxWidth)-1);
      }
      if (divider === 'Vertical' && switchs === 'off'){
        return ((Number(boxHeight))*(Number(boxWidth)-1))+(((Number(boxHeight)-1)*4)*(95*0.00328084))*(Number(boxWidth)-1);
      }
      if (divider === 'Horizontal' && switchs === 'on'){
        return ((Number(boxHeight)-1)*(Number(boxWidth)))+(((Number(boxHeight)-1)*4)*(95*0.00328084))*(Number(boxWidth)-1);
      }
      if (divider === 'Horizontal' && switchs === 'off'){
        return ((Number(boxHeight)-1)*(Number(boxWidth)-1))+(((Number(boxHeight)-1)*4)*(95*0.00328084))*(Number(boxWidth));
      }
    }
  };

  // const Fixed = () => {
  //   return (8 * (Number(boxHeight) - fanLeft));
  // };



  
  const Beeding = () => {
    return Frame();
  };

  const louverBeeding = () => {
    if(Number(boxHeight) === 1 && Number(boxWidth) > 1){
      if(switchFixed === 'on'){
        return 0;
      }else{
        return ((Number(boxHeight) + Number(boxWidth)-1)*2);
      }
    }else if(Number(boxHeight) > 1 && Number(boxWidth) === 1){
      if(switchFixed === 'on'){
        return 0;
      }else{
        return ((Number(boxHeight)-1 + Number(boxWidth))*2);
      }
    }else{
      if (divider === 'Vertical' && switchs === 'on'){
        return (2 * (Number(boxHeight)-1 + Number(boxWidth)-1));
      }
      if (divider === 'Vertical' && switchs === 'off'){
        return (2 * (Number(boxHeight) + Number(boxWidth)-1));
      }
      if (divider === 'Horizontal' && switchs === 'on'){
        return (2 * (Number(boxHeight)-1 + Number(boxWidth)));
      }
      if (divider === 'Horizontal' && switchs === 'off'){
        return (2 * (Number(boxHeight)-1 + Number(boxWidth)-1));
      }
    }
  };

  const frameGasket = () => {
    return Frame();
  };

  const beedingGasket = () => {
    return Frame()+(boxWidth*2);
  };

  const Fixed = () => {
    return (8 * (boxHeight - fanLeft));
  };
  const steelFrame = () => {
    return Frame();
  };

  const steelMullion = () => {
    if(Number(boxHeight) === 1){
      return  Number(boxHeight);
    }else if(Number(boxWidth)=== 1){
      return  Number(boxWidth);
    }else{
      if(divider === 'Vertical'){
        return  Number(boxHeight) + fanLength;
      }else{
        return  Number(boxWidth) + fanLength;
    }
  }
  };
  
 

  const frameArea = Number(boxHeight) * Number(boxWidth);

  const values = {
    mullionCost:{
      wpf: data.mullion.weight/data.mullion.length,
      rw: (data.mullion.weight/data.mullion.length)*Mullion(),
      rpf: data.mullion.amount,
      amount: ((data.mullion.weight/data.mullion.length)*Mullion())*data.mullion.amount,
    },
    frameCost:{
      wpf: data.frame.weight/data.frame.length,
      rw: (data.frame.weight/data.frame.length)*Frame(),
      rpf: data.frame.amount,
      amount: ((data.frame.weight/data.frame.length)*Frame())*data.frame.amount,
    },
    fan:{
      qty: 1,
      price: data.fan.type1,
      amount: data.fan.type1*1
    },
    glass:{
      qty: Glass(),
      price: data.glass,
      amount: data.glass*(((boxHeight-1) * 1) + ((95*0.00328084)*(boxWidth-1))*(boxHeight*4))
    },
    screws:{
      qty:Math.ceil(frameArea+ (Number(boxHeight) + Number(boxWidth) )*1.6),
      price:data.screwsCost,
      amount:Math.ceil(frameArea+ ((Number(boxHeight) + Number(boxWidth) ))*1.6)*data.screwsCost
    },
    LouverBlades:{
      qty: LouverBlades(),
      price: data.LouverBlades,
      amount: data.LouverBlades*LouverBlades()
    },
    marketing:{
      qty: frameArea,
      price: data.marketingCost,
      amount: frameArea*data.marketingCost
    },
    transport:{
      qty: frameArea,
      price: data.transportCost,
      amount:frameArea*data.transportCost
    },
    labourF:{
      qty: frameArea,
      price: data.labourFcost,
      amount:frameArea*data.labourFcost
    },
    labourS:{
      qty: frameArea,
      price: data.labourScost,
      amount:frameArea*data.labourScost
    },
    margin:{
      qty: frameArea,
      price: data.marginCost,
      amount:frameArea*data.marginCost
    },
    beeding:{
      wpf: data.beeding.weight > 0 ? data.beeding.weight/data.beeding.length : 0,
      rw: data.beeding.weight > 0 ? (data.beeding.weight/data.beeding.length)*Beeding() : 0,
      rpf: data.beeding.weight > 0 ? data.beeding.amount : 0,
      amount: data.beeding.weight > 0 ? ((data.beeding.weight/data.beeding.length)*Beeding())*data.beeding.amount : 0,
    },
    lovBeeding:{
      wpf: data.Lbeeding.weight > 0 ? data.Lbeeding.weight/data.Lbeeding.length : 0,
      rw: data.Lbeeding.weight > 0 ? (data.Lbeeding.weight/data.Lbeeding.length)*louverBeeding() : 0,
      rpf: data.Lbeeding.weight > 0 ? data.Lbeeding.amount : 0,
      amount: data.Lbeeding.weight > 0 ? ((data.Lbeeding.weight/data.Lbeeding.length)*louverBeeding())*data.Lbeeding.amount : 0,
    },
    frameGasket:{
      wpf: data.gasket.weight/data.gasket.length,
      rw: (data.gasket.weight/data.gasket.length)*frameGasket(),
      rpf: data.gasket.amount,
      amount: ((data.gasket.weight/data.gasket.length)*frameGasket())*data.gasket.amount,
    },
    beedingGasket:{
      wpf: data.gasket.weight/data.gasket.length,
      rw: (data.gasket.weight/data.gasket.length)*beedingGasket(),
      rpf: data.gasket.amount,
      amount: ((data.gasket.weight/data.gasket.length)*beedingGasket())*data.gasket.amount,
    },
    steelFrame:{
      qty: steelFrame(),
      price: data.steelFrame,
      amount: steelFrame()*data.steelFrame,
    },
    steelMullion:{
      qty: steelMullion(),
      price: data.steelMullion,
      amount: steelMullion()*data.steelMullion,
    },
  };
  

  const frameTotal = Number(values.mullionCost.amount + values.frameCost.amount + values.beeding.amount + values.beedingGasket.amount + values.frameGasket.amount +  values.lovBeeding.amount);
  const wastage = frameTotal *data.wastage/100;
  const frps = (frameTotal+wastage) / frameArea;

  const hardTotal =  Number(values.screws.amount + values.glass.amount + values.LouverBlades.amount + values.steelFrame.amount + values.steelMullion.amount);
  const hrps = hardTotal/frameArea;
   
  const prodTotal = values.marketing.amount + values.transport.amount + values.labourF.amount + values.labourS.amount;
  const prps = prodTotal/frameArea;
  
  const marginTotal = values.margin.amount;
  const mrps = marginTotal/frameArea;
  
  const grandTotal = frameTotal + hardTotal + prodTotal + wastage + marginTotal;
  const grandAps = grandTotal/frameArea;
    
  const coreData = {user: users,  rowId: rowid, quoteId, data:{item, selectedProfile, series, qty, data, boxWidth, boxHeight, fanTop, fanLeft, divider, switchs, switchFixed, fanType, frameArea, deviceWidth, screen, values, frameTotal, wastage, hardTotal, prodTotal, marginTotal, grandTotal}
    };

    //console.log(coreData);

    const sendDataToUrl = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/edit-quote/', {coreData});
        //console.log('Response:', response.data);
        if(response.data.status === 'success'){
          navigate(`/quotesdet?id=${quoteIds}`);
        }
        // Handle response as needed
      } catch (error) {
        console.error('Error sending data:', error);
        // Handle error as needed
      }
    };

    
    const profileDatas = {width: Number(boxWidth), series, height: Number(boxHeight), series, profileId: selectedProfile, mesh: 'no', shutterCount: 0, type: item}

    const handleSeriesChange = (event) => {
      const id = event.target.value;
      setSeries(id);
      sendprofileDataToUrl();
    };



    
    const sendprofileDataToUrl = async () => {
      setCalcBtn(true);
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/find-default/', profileDatas);
        console.log('Response:', response.data);
        if(response.data.status === 'success'){
           if(response.data.data[0].beeding !== ''){
            setSelectedBeedingSeries(response.data.data[0].beedingSeries);
            setSelectedBeedingId(response.data.data[0].beeding);
            setSelectedLBeedingSeries(response.data.data[0].beedingSeries);
            setSelectedLBeedingId(response.data.data[0].beeding);
           }
           if(response.data.data[0].frame !== ''){
            setSelectedFrameSeries(response.data.data[0].frameSeries);
            setSelectedFrameId(response.data.data[0].frame);
           }
           if(response.data.data[0].interlock !== ''){
            //alert('interlock');
           }
           if(response.data.data[0].mesh !== ''){
           // setSelectedMeshSeries(response.data.data[0].meshSeries);
           // setSelectedMeshId(response.data.data[0].mesh);
           }
           if(response.data.data[0].mullion !== null){
            setSelectedMullionSeries(response.data.data[0].mullionSeries);
            setSelectedMullionId(response.data.data[0].mullion); 
           }
           if(response.data.data[0].panel !== ''){
            //alert('panel');
           }
           if(response.data.data[0].shutter !== ''){
            //setSelectedShutterSeries(response.data.data[0].shutterSeries);
            //setSelectedShutterId(response.data.data[0].shutter);
           }
           if(response.data.data[0].cupler !== ''){
            //setSelectedCuplerSeries(response.data.data[0].cuplerSeries);
            //setSelectedCuplerId(response.data.data[0].cupler);
           }

        }
      } catch (error) {
        console.error('Error sending data:', error);
      }
      setCalcBtn(false);
      setIsProfile(true);
      addDef();
    };

    //console.log(newWidths);
   // console.log(numSecondInnerBoxes);
    return (
    <div>
    <Navbar />
    <Header />
    
    <div class="pc-container">
      <div class="pc-content">
       
        <div class="row">
        
          <div class="col-md-12 col-xxl-4 col-xl-4">
            <div class="card">
              <div class="card-body">
              <div class=" row align-items-center">
                    <div class="col-6">
                      <p>Width </p>
                      <div class="input-group mb-2">
                        <button class="btn btn-danger" type="button" onClick={() =>  handleWidth('minus')}>-</button>
                        <input type="number" step="0.01" class="form-control" value={Number(boxWidth)} onChange={handleWidthChange} />
                        <button class="btn btn-success" type="button" onClick={() =>  handleWidth('plus')}>+</button>
                      </div>
                    </div>

                    <div class="col-6">
                      <p>Height</p>
                      <div class="input-group mb-2">
                        <button class="btn btn-danger" type="button" onClick={() =>  handleHeight('minus')}>-</button>
                        <input type="number" step="0.01" class="form-control" value={Number(boxHeight)} onChange={handleHeightChange} />
                        <button class="btn btn-success" type="button" onClick={() =>  handleHeight('plus')}>+</button>
                      </div>
                    </div>
                    <div class="col-12">
                      <p>No of Ventilators</p>
                        <div class="input-group mb-2">
                          <button class="btn btn-danger" type="button" onClick={() =>  handleQty('minus')}>-</button>
                          <input type="number" step="0.01" class="form-control"  value={qty} onChange={handleQtyChange} />
                          <button class="btn btn-success" type="button" onClick={() =>  handleQty('plus')}>+</button>
                        </div>
                    </div>
              </div>
              {Number(boxHeight) > 1 && Number(boxWidth) > 1 ? (  
                <>
              <p>Fan Position</p>
              <div class="input-group mb-2">
                    <button class="btn btn-info w-50" type="button" onClick={changefanTop}>{fanTop}</button>
                    <button class="btn btn-warning w-50" type="button" onClick={changefanLeft}>{fanLeft}</button>
              </div>
              <p>Fixed/Louvers</p>
              <div class="input-group mb-2">
                    <button class="btn btn-secondary w-50" type="button" onClick={changeSwitch}>Switch</button>
                    <button class="btn btn-primary w-50" type="button" onClick={changeDevider}>{divider}</button>
              </div>
             
            
              <p>Fan Type</p>
              <div class="input-group mb-2">
                    <button className={fanType === 1 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(1)}>12 Inch</button>
                    <button className={fanType === 2 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(2)}>9 Inch</button>
                    <button className={fanType === 3 ? 'btn  w-33 btn-success' : ' btn w-33 btn-secondary'} type="button" onClick={() => handleButtonClick(3)}>7 Inch</button>
              </div>
              </>
              ):(
                <button className='btn w-100 btn-secondary' type="button" onClick={changeFixed}>Switch</button>
              )}
             
             <div class="col-12 mb-2 mt-3">
                    <p>Select Profile</p>
                    <select type="text" className="form-control" value={customProfile} onChange={handleProfileChange}>
                    {profileDetails && profileDetails.data && profileDetails.data.map((profiles, index) => (
                      <option className="form-control" value={index}>{profiles.profileName}</option>
                    ))}
                    <option className="form-control text-sm text-center" disabled>Add more Profile contact Administration</option>
                    </select>
              </div>


              <div class="mt-3 row align-items-center">
                  <div class="col-6 mb-2 mt-2">
                 
                      <select className="form-control" value={series} onChange={handleSeriesChange}>
                        <option value="" hidden>{state?.series}</option>
                        <option className="form-control" value="50 Series">50 Series</option>
                        <option className="form-control" value="60 Series">60 Series</option>
                      </select>
                  </div>

                  <div class="col-6 mb-2 mt-2 ">
                      <button class={calcBtn === false ? "btn btn-info w-100" : "btn btn-secondary w-100"} onClick={sendprofileDataToUrl}>{calcBtn === false ? 'Load Profile' : 'Profile Loading...'}</button>
                  </div>
                  </div>
              




              </div>
            </div>
          </div>
           
         


          <div class="col-md-12 col-xxl-8 col-xl-8">
            <div class="card" style={{background:'#fff'}}>
              <div class="card-body mb-5">
           {Number(boxHeight) > 1 && Number(boxWidth) > 1 && (  
            <>

            <div style={{float:'left', width: Number(boxWidth)*screen+'vw', height:Number(boxHeight)*screen+'vw', border:'1px solid #333'}}>
           
                {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Vertical' && switchs === 'on' && (
                    <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                      <div style={{float:'right', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`}}></div>
                    <div style={{float:'right', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Left' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left' && divider === 'Vertical' && switchs === 'off' && (
                    <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                      <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                      <div style={{float:'right', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                      <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                    </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Right' &&  divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Vertical' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'right', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(.85) * screen}vw - 0px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)) * screen}vw - 20px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px', marginTop: `calc(-${(Number(boxHeight)-.85) * screen}vw + 20px)`, border:'1px solid #333', background: 'lightblue'}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Left' && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, marginRight: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginBottom: '4px'}} />
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', background: 'lightblue'}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'on' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', background: 'lightblue'}}></div>
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, marginLeft: '5px', marginBottom: '4px',  border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginBottom: '4px'}} />
                  </div>
                )}

                {fanTop === 'Top' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginTop: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Top' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '4px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, margin: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '0px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                  </div>
                )}
                
                {fanTop === 'Bottom' && fanLeft === 'Right'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                    <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <img src={Fan} style={{float:'right', width: .85*screen+'vw', marginRight: '4px', marginTop: '0px'}} />
                    <div style={{float:'left', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, marginLeft: '4px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}

                {fanTop === 'Bottom' && fanLeft === 'Left'  && divider === 'Horizontal' && switchs === 'off' && (
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${(Number(boxHeight)-.85) * screen}vw - 25px)`, width: `calc(${(Number(boxWidth)) * screen}vw - 20px)`, margin: '4px', marginTop: '5px', border:'1px solid #333', backgroundImage: `repeating-linear-gradient(lightblue, white ${(10/Number(boxHeight))*screen}%, lightblue ${(10/Number(boxHeight))*screen}%)`}}></div>
                    <img src={Fan} style={{float:'left', width: .85*screen+'vw', marginLeft: '4px', marginRight: '4px'}} />
                    <div style={{float:'right', height: `calc(${(.85) * screen}vw - 0px)`, width: `calc(${(Number(boxWidth)-.85) * screen}vw - 26px)`, marginRight: '5px',  border:'1px solid #333', background: 'lightblue' }}></div>
                  </div>
                )}

            </div>
                
            </>
            
          )} 

          {Number(boxHeight) == 1 && Number(boxWidth) > 1 && (
            <>
           {switchFixed === 'on' && (  
            
              <div style={{float:'left', width: Number(boxWidth)*screen+'vw', height:Number(boxHeight)*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(Number(boxHeight))*screen}vw - 22px)`, width: `calc(${(Number(boxWidth)- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
            )}

            {switchFixed === 'off' && (  
            
            <div style={{float:'left', width: Number(boxWidth)*screen+'vw', height:Number(boxHeight)*screen+'vw', border:'1px solid #333'}}>
              <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(Number(boxHeight))*screen}vw - 22px)`, width: `calc(${(Number(boxWidth)- .94)*screen}vw - 10px)`, marginRight: '5px', marginTop: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`, border:'1px solid #333'}}></div>
              </div>
            </div>
          )}
            </>
          )}

          {Number(boxHeight) > 1 && Number(boxWidth) == 1 && (
            <>
             {switchFixed === 'on' && (  
              <div style={{float:'left', width: Number(boxWidth)*screen+'vw', height:Number(boxHeight)*screen+'vw', border:'1px solid #333'}}>
                  <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                  <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                  <div style={{float: 'right', height: `calc(${(Number(boxHeight)-.75)*screen}vw - 25px)`, width: `calc(${(Number(boxWidth))*screen}vw - 20px)`, marginRight: '5px', background:'lightblue', border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
             
             {switchFixed === 'off' && (
              <div style={{float:'left', width: Number(boxWidth)*screen+'vw', height:Number(boxHeight)*screen+'vw', border:'1px solid #333'}}>
                <div style={{float:'left', height: `calc(${Number(boxHeight) * screen}vw - 10px)`, width: `calc(${Number(boxWidth) * screen}vw - 10px)`, margin: '4px', border:'1px solid #333'}}>
                <img src={Fan} style={{float:'left', width: .75*screen+'vw', margin: '4px', }} />
                <div style={{float: 'right', height: `calc(${(Number(boxHeight)-.75)*screen}vw - 25px)`, width: `calc(${(Number(boxWidth))*screen}vw - 20px)`, marginRight: '5px',  backgroundImage: `repeating-linear-gradient(lightblue, white ${(6/Number(boxHeight))*screen}%, lightblue ${(6/Number(boxHeight))*screen}%)`, border:'1px solid #333'}}></div>
                </div>
              </div>
             )}
            </>
          )}
          
          {(Number(boxWidth) > 1 || Number(boxHeight) > 1) && (
            <>
              <div className="expanding-arrow-line" style={{ 
                  position: 'absolute',
                  float:'left', 
                  width: `calc(${Number(boxWidth) * screen}vw )`,
                  bottom:'-10px', 
                  top: `calc(${Number(boxHeight)* screen}vw - 25px)`,
                  }}> 
                <div className="arrow-left"></div>
                  <div className="line" align="center">
                    <div style={{marginTop:'-7px',background:'white',maxWidth:'20px',textAlign:'center'}}><p>{typeof Number(boxWidth) === 'number' ? Number(boxWidth).toFixed(2) : Number(boxWidth)}</p></div>
                  </div>
                  <div className="arrow-right"></div>
                </div>

            <div style={{ width: '30px', float: 'left', paddingLeft: '15px', textAlign: 'left' }}>
              <div className="expanding-arrow-line" style={{ float: 'left', height: `calc(${Number(boxHeight) * screen}vw)`, top: '0', position: 'relative' }}>
                <div className="arrow-up" style={{ borderBottom: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
                  <div className="line" style={{ width: '1px', height: '100%', marginLeft: '0px', position: 'absolute', top: '0' }}>
                    <div style={{ marginLeft: '-10px', marginTop: Number(boxHeight) * (screen/2)+ 'vw', background: 'white', maxWidth: '50px', textAlign: 'center' }}><p style={{width:'20px'}}>{typeof Number(boxHeight) === 'number' ? Number(boxHeight).toFixed(2) : Number(boxHeight)}</p></div>
                  </div>
                <div className="arrow-down" style={{ borderTop: '3px solid black', borderLeft: '5px solid transparent', borderRight: '5px solid transparent', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}></div>
              </div>
            </div>  
            </>
          )}


             </div>
            </div>
          </div>

        
     
          <div class="col-md-12 col-xxl-12">
            <div class="card">
              <div class="card-header">
                <h5>Profile Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th>Product Description</th>
                      <th>Required Profile</th>
                      <th width="10%">Req. Qty (Feet)</th>
                      <th width="10%">Kg/Feet</th>
                      <th width="10%">Req. Qty (Kg)</th>
                      <th width="10%">Rate/Kg</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Outer Frame</td>
                      <td class="container-line">
                        <select class="mb-3 form-select-select item-line w-25" value={selectedFrameSeries || ''} onChange={handleFrameSeriesChange}>
                          <option value="">Select Series</option>
                          {profileData && profileData.map(seriesId => (
                            <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                          ))}
                        </select>
                        <select class="mb-3 form-select-select item-line w-75" value={selectedFrameId || ''} onChange={handleFrameIdChange}>
                          {selectedFrameSeries ? (<option value="">Select ID</option>):(<option value="">Select Series First</option>)}
                            {selectedFrameSeries && seriesDataFrame.items.map((item) => (
                              <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                          ))}
                        </select>
                      </td>
                      <td>{numeral(Frame()*qty).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.wpf*qty).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.rw*qty).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.rpf*qty).format('0,0.00')}</td>
                      <td>{numeral(values.frameCost.amount*qty).format('0,0.00')}</td>
                    </tr>
                    <tr>
                      <td>Mullion</td>
                      <td class="container-line">
                <select class="mb-3 form-select-select item-line w-25" value={selectedMullionSeries || ''} onChange={handleMullionSeriesChange}>
                  <option value="">Select Series</option>
                  {profileData && profileData.map(seriesId => (
                    <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                  ))}
                </select>
                <select class="mb-3 form-select-select item-line w-75" value={selectedMullionId || ''} onChange={handleMullionIdChange}>
                {selectedMullionSeries ? (<option value="">Select ID</option>):(<option value="">Select Series </option>)}
                    {seriesDataMullion && seriesDataMullion.items.map((item) => (
                      <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                    ))}
                  </select>
              </td>
                      <td>{numeral(Mullion()*qty).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.wpf*qty).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.rw*qty).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.rpf*qty).format('0,0.00')}</td>
                      <td>{numeral(values.mullionCost.amount*qty).format('0,0.00')}</td>
                    </tr>
                    <tr>
              <td>Beeding</td>
              <td class="container-line">
                  <select class="mb-3 form-select-select item-line w-25" value={selectedBeedingSeries || ''} onChange={handleBeedingSeriesChange}>
                    <option value="">Select Series</option>
                      {profileData && profileData.map(seriesId => (
                        <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                      ))}
                  </select>
                    <select class="mb-3 form-select-select item-line w-75" value={selectedBeedingId || ''} onChange={handleBeedingIdChange}>
                      {selectedBeedingSeries ? (<option value="">Select ID</option>):(<option value="">Select Series First</option>)}
                      {seriesDataBeeding && seriesDataBeeding.items.map((item) => (
                        <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                      ))}
                    </select>
                </td>
              <td>{numeral(Beeding()*qty).format('0,0.00')}</td>
              <td>{numeral(values.beeding.wpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rw*qty).format('0,0.00')}</td>
              <td>{numeral(values.beeding.rpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.beeding.amount*qty).format('0,0.00')}</td>
            </tr>

            <tr>
              <td>Louver Beeding</td>
              <td class="container-line">
                  <select class="mb-3 form-select-select item-line w-25" value={selectedLBeedingSeries || ''} onChange={handleLBeedingSeriesChange}>
                    <option value="">Select Series</option>
                      {profileData && profileData.map(seriesId => (
                        <option key={seriesId.series} value={seriesId.series}>{seriesId.series}</option>
                      ))}
                  </select>
                    <select class="mb-3 form-select-select item-line w-75" value={selectedLBeedingId || ''} onChange={handleLBeedingIdChange}>
                      {selectedLBeedingSeries ? (<option value="">Select ID</option>):(<option value="">Select Series First</option>)}
                      {seriesDataLBeeding && seriesDataLBeeding.items.map((item) => (
                        <option key={item.id} value={item.id}>{item.id+' - '+ item.name}</option>
                      ))}
                    </select>
                </td>
              <td>{numeral(louverBeeding()*qty).format('0,0.00')}</td>
              <td>{numeral(values.lovBeeding.wpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.lovBeeding.rw*qty).format('0,0.00')}</td>
              <td>{numeral(values.lovBeeding.rpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.lovBeeding.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Frame Gasket</td>
              <td>{numeral(frameGasket()*qty).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.wpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rw*qty).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.rpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.frameGasket.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td colspan='2'>Beeding Gasket</td>
              <td>{numeral(beedingGasket()*qty).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.wpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rw*qty).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.rpf*qty).format('0,0.00')}</td>
              <td>{numeral(values.beedingGasket.amount*qty).format('0,0.00')}</td>
            </tr>
                    <tr>
                      <td colspan="6">Total</td>
                      <td>{numeral(frameTotal*qty).format('0,0.00')}</td>
                    </tr>
                    <tr>
                    <th>Wastage( {data.wastage}% )</th>
              <th ><select class="form-select-select item-line w-75" value={wastageCost} onChange={wasteChange}>
                  <option value="" hidden>Select Model</option>
                  {wasteValues && Object.entries(wasteValues).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+Number(value).toFixed(2)}</option>
                  ))}
                </select>
               
                </th>
                <th colspan="4"></th>
                      <th>{numeral(frameTotal+wastage*qty).format('0,0.00')}</th>
                    </tr>
                    <tr>
                      <th colspan="6">Profile Cost/Sqft</th>
                      <th>{numeral(frps*qty).format('0,0.00')}</th>
                    </tr>


                  </tbody>
                </table>
            </div>   
            <hr />

              <div class="card-header">
                <h5>Hardware Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th colspan="2">Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Steel Frame</td>
              <td>
              <select class="form-select-select item-line w-75" value={steelFramCost} onChange={steelframeChange}>
                  <option value="" hidden>Select Model</option>
                  {steelValues && Object.entries(steelValues).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.steelFrame.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.steelFrame.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Steel Mullion</td>
              <td>
              <select class="form-select-select item-line w-75" value={steelMullionCost} onChange={steelMullionChange}>
                  <option value="" hidden>Select Model</option>
                  {steelMullionValues && Object.entries(steelMullionValues).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.steelMullion.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.steelMullion.amount*qty).format('0,0.00')}</td>
            </tr>
                  <tr>
                
                     <td >Screws</td>
                     <td>
              <select class="form-select-select item-line w-75" value={screwtCost} onChange={screwChange}>
                  <option value="" hidden>Select Model</option>
                  {screwValues && Object.entries(screwValues).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
                     <td>{numeral(values.screws.qty*qty).format('0,0.00')}</td>
                     <td>{numeral(values.screws.price*qty).format('0,0.00')}</td>
                     <td>{numeral(values.screws.amount*qty).format('0,0.00')}</td>
                  </tr>
                  <tr>
                     <td>Glass</td>
                     <td>
                <select class="form-select-select item-line w-75" value={glassCost} onChange={glassChange}>
                  <option value="" hidden>Select Glass Cost</option>
                  {glasses && Object.entries(glasses).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
                     <td>{numeral(values.glass.qty*qty).format('0,0.00')}</td>
                     <td>{numeral(values.glass.price*qty).format('0,0.00')}</td>
                     <td>{numeral(values.glass.amount*qty).format('0,0.00')}</td>
                  </tr>
                  {(boxHeight > 1 && boxWidth > 1) || (switchFixed === 'off') ? (
                  <tr>
                     <td >Louver Blades</td>
                     <td>
              <select class="form-select-select item-line w-75" value={LouverBladesCost} onChange={LouverBladesChange}>
                  <option value="" hidden>Select Model</option>
                  {LouverBladesValues && Object.entries(LouverBladesValues).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+value}</option>
                  ))}
                </select>
              </td>
                     <td>{numeral(values.LouverBlades.qty*qty).format('0,0.00')}</td>
                     <td>{numeral(values.LouverBlades.price*qty).format('0,0.00')}</td>
                     <td>{numeral(values.LouverBlades.amount*qty).format('0,0.00')}</td>
                  </tr>
                  ):(<></>)}
                  <tr>
                    <th colspan="4">Total</th>
                    <th>{numeral(hardTotal*qty).format('0,0.00')}</th>
                  </tr>
                  <tr>
                    <th colspan="4">Hardware Cost/Sqft</th>
                    <th>{numeral(hrps*qty).format('0,0.00')}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
              {userType != '3' && (<>
              <hr />

              <div class="card-header ">
                <h5>Margin Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th colspan="2">Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Margin</td>
              <td>
                <select class="form-select-select item-line w-75" value={marginCost} onChange={marginChange}>
                  <option value="" hidden>Select Margin Cost</option>
                  {margin && Object.entries(margin).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.margin.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.margin.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.margin.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <th colspan="4">Total</th>
              <th>{numeral(marginTotal*qty).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="4">Margin Cost/Sqft</th>
              <th>{numeral(mrps*qty).format('0,0.00')}</th>
            </tr>
            </tbody>
                </table>
              </div>
              <hr />

              <div class="card-header">
                <h5>Production Cost</h5>
              </div>
              <div class="card-body">
              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    <tr>
                      <th colspan="2">Product Description</th>
                      <th width="10%">Req. Qty </th>
                      <th width="10%">Price</th>
                      <th width="10%">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
              <td>Markting OH</td>
              <td>
              <select class="form-select-select item-line w-75" value={marketingCost} onChange={marketingChange}>
                  <option value="" hidden>Select Marketing Coast</option>
                  {marketing && Object.entries(marketing).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.marketing.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.marketing.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.marketing.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Transport</td>
              <td>
                <select class="form-select-select item-line w-75" value={transportCost} onChange={transportChange}>
                  <option value="" hidden>Select Margin Cost</option>
                  {transport && Object.entries(transport).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.transport.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.transport.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.transport.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Factory)</td>
              <td>
              <select class="form-select-select item-line w-75" value={labourFCost} onChange={labourFChange}>
                  <option value="" hidden>Select Labour Coast</option>
                  {labourF && Object.entries(labourF).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.labourF.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.labourF.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.labourF.amount*qty).format('0,0.00')}</td>
            </tr>
            <tr>
              <td>Labour (Site)</td>
              <td>
              <select class="form-select-select item-line w-75" value={labourSCost} onChange={labourSChange}>
                  <option value="" hidden>Select Labour Coast</option>
                  {labourS && Object.entries(labourS).map(([key, value]) => (
                    <option key={key} value={value}>{key+' - ₹ '+ Number(value).toFixed(2)+'/sqft'}</option>
                  ))}
                </select>
              </td>
              <td>{numeral(values.labourS.qty*qty).format('0,0.00')}</td>
              <td>{numeral(values.labourS.price*qty).format('0,0.00')}</td>
              <td>{numeral(values.labourS.amount*qty).format('0,0.00')}</td>
            </tr>
            
            <tr>
              <th colspan="4">Total</th>
              <th>{numeral(prodTotal*qty).format('0,0.00')}</th>
            </tr>
            <tr>
              <th colspan="4">Production Cost/Sqft</th>
              <th>{numeral(prps*qty).format('0,0.00')}</th>
            </tr>

            </tbody>
                </table>
              </div>
              </>
            )}


          </div>
        </div>

        <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Area</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">{frameArea*qty} sqft</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Total</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandTotal*qty).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Rate/Sqft</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-3 rounded">
                  <div class="mt-3 row align-items-center">
                    <div class="col-12">
                      <h6 class="mb-1">₹ {numeral(grandAps).format('0,0.00')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xxl-3 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-0">Save Quote</h6>
                  </div>
                </div>
                <div class="bg-body p-3 mt-2 rounded">
                  <div class="row align-items-center">
                    <div class="col-12">
                    <button class="btn btn-success w-100" onClick={sendDataToUrl}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





        
        </div>
      </div>
    </div>


    </div>
    );
}
export default Editquotees
