import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";

 function Quote() {
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const [quoteData, setData] =useState('');
    const Empid = ReactSession.get("empid");
    const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [searchQuery3, setSearchQuery3] = useState('');
  const [searchQuery5, setSearchQuery5] = useState('');

  // Event handlers
  const handleSearchInputChangeID = (e) => setSearchQuery(e.target.value);
  const handleSearchInputChangeName = (e) => setSearchQuery2(e.target.value);
  const handleSearchInputChangedate = (e) => setSearchQuery3(e.target.value);
  const handleSearchInputChangeLocation = (e) => setSearchQuery5(e.target.value);

    let users='';
    if (userType === '3') {
      users =  Empid ;
    } else {
      users = userId ;
    }
    const user = { "userId": users };


    const filteredData = quoteData?.data?.filter(item => 
      (searchQuery2 === '' || (item.client?.name?.toLowerCase()?.includes(searchQuery2.toLowerCase()) ?? false)) &&
      (searchQuery3 === '' || (item.date?.toLowerCase()?.includes(searchQuery3.toLowerCase()) ?? false)) &&
      (searchQuery === '' || (item.quoteId?.toLowerCase()?.includes(searchQuery.toLowerCase()) ?? false)) &&
      (searchQuery5 === '' || (item.client?.location?.toLowerCase()?.includes(searchQuery5.toLowerCase()) ?? false))
    );
    

 
  

    const fetchData = async () => {
        try {
            const response = await axios.post('https://upvcapi.sninfoserv.com/all-quotes/', user);
            setData(response.data);
            console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      

      
      
  useEffect(() => {
    fetchData();
  }, []);
 
  return (
    <div>
    <Navbar />
    <Header />
    
    <div class="pc-container">
      <div class="pc-content">
        
        <div class="row">
        <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
             
               
            
              <div class="card-header ">
                <h5>Table</h5>
              </div>
              <div class="card-body">
                  <div class="table-responsive"> 


                  <div className="row mb-3" >
                  <div className="col-md-6   col-lg-3 ml-2 mt-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search by user Date..."
               value={searchQuery3}
              onChange={handleSearchInputChangedate}
            />
          </div>
          
          <div className="col-md-6  col-lg-3 ml-2 mt-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search by user ID..."
               value={searchQuery}
              onChange={handleSearchInputChangeID}
            />
          </div>
          <div className="col-md-6  col-lg-3 ml-2 mt-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search by user name..."
               value={searchQuery2}
              onChange={handleSearchInputChangeName}
            />
          </div>
         
          <div className="col-md-6  col-lg-3 ml-2 mt-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search by user location..."
               value={searchQuery5}
              onChange={handleSearchInputChangeLocation}
            />
          </div>
            </div>

              <table id="left-right-fix" class="table stripe row-border order-column">
                  <thead>
                    
                    <tr>
                    <th>Date</th>
                      <th>Quote ID</th>
                      <th>Items</th>
                      <th>Client</th>
                      <th>Location</th>
                      <th>Action</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                  {filteredData?.length > 0 ? (
            <>  {filteredData?.map((quotes, index) => (

              <tr key={index}>
                 <td>{quotes.date}</td>
                  <td>{quotes.quoteId}</td>
                  <td>{quotes.items}</td>
                  <td>{quotes.client.name}</td>
                  <td class="text-wrap">{quotes.client.location}</td>
               
            
      <td> <div class="col-6">
                    <a href={`quotesdet?id=${quotes.quoteId}`} class="btn btn-primary w-120" >view Quote</a>
                    </div></td>
    </tr>
                ))} </>
              ) : searchQuery && filteredData.length === 0 ? (
                       <tr >
                         <td class="text-center" colspan='6'>No Data Found</td>
                       </tr>
         ) : (<>
               
                   {quoteData && quoteData.data && quoteData.data.reverse().map((quotes, index) => (
                <tr key={index}>
                 <td>{quotes.date}</td>
                  <td>{quotes.quoteId}</td>
                  <td>{quotes.items}</td>
                  <td>{quotes.client.name}</td>
                  <td>{quotes.client.location}</td>
               
            
      <td> <div class="col-6">
                    <a href={`quotesdet?id=${quotes.quoteId}`} class="btn btn-primary w-120" >view Quote</a>
                    </div></td>
    </tr>
    ))}
    </>
         )}

</tbody>


                </table>
                </div>
              </div>
              <hr />

              


            </div>
          </div>

    </div>
      </div>
    </div>



    </div>
  )
}
export default Quote;