import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
ReactSession.setStoreType("localStorage");

const App = () => {

    const initialvalue={email: '', password:''};
    const [formvalue, setvalue]=useState(initialvalue);
    const [formError, setError]=useState({});
    const [formsubmit, setsubmit]=useState(false);
    const navigate = useNavigate();

    if(!ReactSession.get("userId")){
      ReactSession.set("userId","0");
    }
    else{
      ReactSession.set("userId", ReactSession.get("userId"));
    }
    
    const handleChange = (e) => {
      const { name, value } = e.target;
          setvalue({...formvalue, [name]: value});
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setsubmit(true);
  
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/user/', formvalue);
        console.log(response.data);
        if(response.data.status === 'success' ){
          ReactSession.set("userId", response.data.data.user_id);
          ReactSession.set("Address", response.data.data.user_address);
          ReactSession.set("Number", response.data.data.user_mobile);
          ReactSession.set("Image", response.data.data.user_image);
          ReactSession.set("userName", response.data.data.user_name);
          ReactSession.set("userType", response.data.data.user_type);
          ReactSession.set("userQuotes", response.data.data.user_quotes);
          ReactSession.set("userEngineers", response.data.data.user_engineers);
          ReactSession.set("userClients", response.data.data.user_clients);
          ReactSession.set("userProfiles", response.data.data.user_profiles);
          ReactSession.set("email", response.data.data.user_email);
          ReactSession.set("regid", response.data.data.user_regId );
          ReactSession.set("limit", response.data.data.user_limit );
          ReactSession.set("subUsers", response.data.data.user_sub );
          ReactSession.set("companyName", response.data.data.company_data.companyName);
          ReactSession.set("companyLogo", response.data.data.company_data.companyLogo);
          ReactSession.set("companyAddress", response.data.data.company_data.companyAddress);
          ReactSession.set("companyGst", response.data.data.company_data.companyGst);
          ReactSession.set("companyPin", response.data.data.company_data.companyPin);
          ReactSession.set("companyLocation", response.data.data.company_data.companyLocation);
          ReactSession.set("companyContact", response.data.data.company_data.companyContact);

          if(response.data.data.employer_id){
            ReactSession.set("empid", response.data.data.employer_id );
          }
          if(response.data.data.user_type === '1'){
            navigate('/dashboard');
          }
          if(response.data.data.user_type === '2'){
            navigate('/dashboard');
          }
          if(response.data.data.user_type === '3'){
            navigate('/dashboard');
          }
          
        }else{
          console.log(response.data.error.message);
          ReactSession.set("userId", "0");
        }
  
        setError(validate(response));
        
        } catch (error) {
          console.error('Login failed!', error);
          ReactSession.set("userId", "0");
        }
  
      
    };
    
    const validate=(response)=>{
      const errors={};
        if(response.data.error){
          errors.name = response.data.error.message;
        }
        return errors;
    }
  
    useEffect(()=>{
       console.log(formError);
      if(Object.keys(formError).length ===0 & formsubmit){
        console.log(formvalue);
      }
    });
    
    const { id } = useParams();
  
    if(id == 'logout'){
      ReactSession.set("userId", "0");
    }


    return (

      <div class="auth-main">
        <div class="auth-wrapper v1">
          <div class="auth-form">
          <img src="assets/images/saalaram.png" width='150px' alt='Logo' />
            <div class="card my-5">
              <div class="card-body">
             
                <form onSubmit={handleSubmit}>
                <h4 class="text-center f-w-500 mb-3">Login</h4>
                <div class="form-group mb-3">
                  <input type="email" class="form-control" placeholder="Email Address" name="email" value={formvalue.email} onChange={handleChange} />
                </div>
                <div class="form-group mb-3">
                  <input type="password" class="form-control" placeholder="Password" name="password" value={formvalue.password} onChange={handleChange} />
                </div>
                <div class="d-grid mt-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                  <p style={{ color: 'red', textAlign: 'center',margin:'10px' }}>{formError.name}</p>
                </div>
                </form>
              </div>
            </div>
            <p> Powered By <img src="assets/images/sni-logo.png" width='90px' alt='Logo' /></p>
          </div>
        </div>
      </div>
    
);
    };
    
    export default App;