import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./navbar";
import Header from "./header";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");

const App = () => {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const userImage = ReactSession.get("Image");
  const empId = ReactSession.get("empid");
  const navigate = useNavigate();
  const indexPath = '/';
  
  const [costData, setCostData] = useState(null);
  const [brandDatas, setBdata] = useState(null);
  const [des, setDes] = useState("");

  let users;
  if(userType === '3'){
    users = empId;
  }else{
    users = userId;
  }

  const user = { "userId": userId };
  
  useEffect(() => {
    if (userId === 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);


  
    const fetchcostData = async () => {
      try {
        const response = await axios.post('https://upvcapi.sninfoserv.com/costs/', user);
        setCostData(response.data.data);
      } catch (error) {
      } 
    };
  useEffect(() => {
    fetchcostData();
  }, []);
 
  
  const [meshPrice, setMeshPrice] = useState([]);
  const [bladePrice, setBladePrice] = useState([]);
  const [meshGasketPrice, setMeshGasketPrice] = useState([]);
  const [proGasketPrice, setProGasketPrice] = useState([]);
  const [wastagePrice, setWastagePrice] = useState([]);
  const [mplPrice, setMplPrice] = useState([]);
  const [hingesPrice, setHingesPrice] = useState([]);
  const [screwPrice, setScrewPrice] = useState([]);
  const [marketingPrice, setMarketingPrice] = useState([]);
  const [transportPrice, setTransportPrice] = useState([]);
  const [labourSPrice, setLabourSPrice] = useState([]);
  const [labourFPrice, setLabourFPrice] = useState([]);
  const [glassPrice, setGlassPrice] = useState([]);
  const [marginPrice, setMarginPrice] = useState([]);
  const [alTrackPrice, setAlTrackPrice] = useState([]);
  const [gRollPrice, setGRollPrice] = useState([]);
  const [meshRollPrice, setMeshRollPrice] = useState([]);
  const [wStripPrice, setWStripPrice] = useState([]);
  const [handlePrice, setHandlePrice] = useState([]);
  const [bArrestorPrice, setBArrestorPrice] = useState([]);
  const [dArrestorPrice, setDArrestorPrice] = useState([]);
  const [jStopperPrice, setJStopperPrice] = useState([]);
  const [boardPrice, setBoardPrice] = useState([]);
  const [steelPrice, setSteelPrice] = useState([]);
  const [grillPrice, setGrillPrice] = useState([]);

  
useEffect(() => {
  setMeshPrice(costData && costData.costData && costData.costData.mesh);
  setBladePrice(costData && costData.costData && costData.costData.blades);
  setMeshGasketPrice(costData && costData.costData && costData.costData.meshGasket);
  setProGasketPrice(costData && costData.costData && costData.costData.profileGasket);
  setWastagePrice(costData && costData.costData && costData.costData.wastage);
  setMplPrice(costData && costData.costData && costData.costData.mpl);
  setHingesPrice(costData && costData.costData && costData.costData.hinges);
  setScrewPrice(costData && costData.costData && costData.costData.screws);
  setMarketingPrice(costData && costData.costData && costData.costData.marketing);
  setTransportPrice(costData && costData.costData && costData.costData.transport);
  setLabourSPrice(costData && costData.costData && costData.costData.labourSite);
  setLabourFPrice(costData && costData.costData && costData.costData.labourFactory);
  setGlassPrice(costData && costData.costData && costData.costData.glass);
  setMarginPrice(costData && costData.costData && costData.costData.margin);
  setAlTrackPrice(costData && costData.costData && costData.costData.alTrack);
  setGRollPrice(costData && costData.costData && costData.costData.gRoll);
  setMeshRollPrice(costData && costData.costData && costData.costData.meshRoll);
  setWStripPrice(costData && costData.costData && costData.costData.wStrip);
  setHandlePrice(costData && costData.costData && costData.costData.handle);
  setBArrestorPrice(costData && costData.costData && costData.costData.bArrestor);
  setDArrestorPrice(costData && costData.costData && costData.costData.dArrestor);
  setJStopperPrice(costData && costData.costData && costData.costData.jStopper);
  setBoardPrice(costData && costData.costData && costData.costData.board);
  setSteelPrice(costData && costData.costData && costData.costData.Steel);
  setGrillPrice(costData && costData.costData && costData.costData.grill);
}, [costData]);


const mplValue = (key, newValue) => {
    setMplPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const steelValue = (key, newValue) => {
  setSteelPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const grillValue = (key, newValue) => {
  setGrillPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const hingesValue = (key, newValue) => {
    setHingesPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const marketingValue = (key, newValue) => {
    setMarketingPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const transportValue = (key, newValue) => {
    setTransportPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const labourSValue = (key, newValue) => {
    setLabourSPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const labourFValue = (key, newValue) => {
    setLabourFPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const glassValue = (key, newValue) => {
    setGlassPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const marginValue = (key, newValue) => {
    setMarginPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const handleValue = (key, newValue) => {
  setHandlePrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const boardValue = (key, newValue) => {
    setBoardPrice(prevPrice => ({
        ...prevPrice,
        [key]: newValue
    }));
};

const meshValue = (key, newValue) => {
  setMeshPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const bladeValue = (key, newValue) => {
  setBladePrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const meshGasketValue = (key, newValue) => {
  setMeshGasketPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const proGasketValue = (key, newValue) => {
  setProGasketPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const screwValue = (key, newValue) => {
  setScrewPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const alTrackValue = (key, newValue) => {
  setAlTrackPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const gRollValue = (key, newValue) => {
  setGRollPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const meshRollValue = (key, newValue) => {
  setMeshRollPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const wStripValue = (key, newValue) => {
  setWStripPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const bArrestorValue = (key, newValue) => {
  setBArrestorPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const dArrestorValue = (key, newValue) => {
  setDArrestorPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const jStopperValue = (key, newValue) => {
  setJStopperPrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};


const wastageValue = (key, newValue) => {
  setWastagePrice(prevPrice => ({
      ...prevPrice,
      [key]: newValue
  }));
};

const newData = {
    mesh:meshPrice,
    blades:bladePrice,
    meshGasket:meshGasketPrice,
	  profileGasket:proGasketPrice,
    Steel:steelPrice,
    wastage:wastagePrice,
    mpl:mplPrice,
    hinges:hingesPrice,
    screws:screwPrice,
    marketing:marketingPrice,
    transport:transportPrice,
    labourSite:labourSPrice,
    labourFactory:labourFPrice,
    glass:glassPrice,
    margin:marginPrice,
    alTrack:alTrackPrice,
    gRoll:gRollPrice,
    meshRoll:meshRollPrice,
    wStrip:wStripPrice,
    handle:handlePrice,
    bArrestor:bArrestorPrice,
    dArrestor:dArrestorPrice,
    jStopper:jStopperPrice,
    board:boardPrice,
    grill:grillPrice
};
    
    const updateData = async () => {
        const sendData = {user: userId, data: newData};
        try {
          const response = await axios.post('https://upvcapi.sninfoserv.com/costs-update/', sendData);
          if(response.data.status === 'success'){
            fetchcostData();
            alert(response.data.message);
          }else{
            alert('Unable to process your request please try again!');
          }
        } catch (error) {
        } 
    };

    const brandData = async () => {
      try {
        const response = await axios.get('https://upvcapi.sninfoserv.com/company/', {params: { userId: users }});
        console.log(response.data);
        if(response.data.status === 'success'){
          setBdata(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching brand data:', error);
      } 
    };
    
    useEffect(() => {
      brandData();
    },[]);



    
    const handleDes = (event) => {
      setDes(event.target.value);
  if (typeof window !== 'undefined') {
  ClassicEditor
    .create(document.querySelector('#classic-editor'), {
      // CKEditor configuration here
    })
    .then(editor => {
      editor.model.document.on('change:data', () => {
        const data = editor.getData();
        setDes(data);
      });
    })
    .catch(error => {
      console.error('Error initializing CKEditor:', error);
    });
}
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData ={
      userId: users,
      terms: des
    };
    console.log(formData);

    try {
       
        const response = await axios.post('https://upvcapi.sninfoserv.com/quote-terms/', formData);
       if(response.data.status=== 'success'){
        alert('Terms & Conditions is Updated...');
       }
        
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
       
    }
  
};
const fetchterms=async()=>{
  try {
       
    const response = await axios.get('https://upvcapi.sninfoserv.com/quote-terms/', {params: { userId: users }});
    console.log("Response data terms:", response.data);
    setDes( response.data.companyTerms)
} catch (error) {
    // Handle error
    console.error('There was a problem with login:', error);
   
}
}
useEffect(()=>{
  fetchterms();
},[])


    return (
    <>
    <Navbar />
    
    <div class="pc-container">
      <div class="pc-content">
        <div class="row">
          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Brand Details</h4>
                <div class="row">
                  <div class="col-md-6 col-xxl-3 col-xl-3 mt-4">
                    <img src={`assets/user/${brandDatas && brandDatas[0].companyLogo}`} width="160px" />
                  </div>
                  <div class="col-md-6 col-xxl-9 col-xl-9 mt-4">
                    <h5 class="mb-3">{brandDatas && brandDatas[0].companyName}</h5>
                    <p class="text-sm">GSTIN : {brandDatas && brandDatas[0].companyGst}</p>
                    <p class="text-sm">Address : {brandDatas && brandDatas[0].companyAddress}</p>
                    <p class="text-sm">Location: {brandDatas && brandDatas[0].companyLocation}</p>
                    <p class="text-sm">Contact : {brandDatas && brandDatas[0].companyContact}</p>
                  </div>
                </div>
              </div>
            </div>

            
          </div>



          <div class="card mb-3">
       <div class="card-body">
        <form onSubmit={handleSubmit}>
       <div class="row">
              <div className="mb-3 col-md-12">
                  <h4 >Terms & Conditions</h4>
                  <textarea
                      className="form-control"
                      type="text"
                      name="des"
                      id="classic-editor"
                      value={des}
                      onChange={handleDes}
                  />
              </div>
              <div align='center'>
              <button type="submit"  className="btn btn-primary mb-4">Submit</button>
              </div>                         
         </div>
         </form>
       </div>
     </div>


       
          <div class="col-md-12 col-xxl-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <h4>Pricing</h4>
                
                <div class="row">
                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Profile Wastage % </label>
                    <div class="row">
                    {wastagePrice && Object.entries(wastagePrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => wastageValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Steel Price</label>
                    <div class="row">
                    {mplPrice && Object.entries(steelPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => steelValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Mesh Price</label>
                    <div class="row">
                    {meshPrice && Object.entries(meshPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => meshValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Mesh Gasket Price</label>
                    <div class="row">
                    {meshGasketPrice && Object.entries(meshGasketPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => meshGasketValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Profile Gasket Price</label>
                    <div class="row">
                    {proGasketPrice && Object.entries(proGasketPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => proGasketValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Louver Blades Price</label>
                    <div class="row">
                    {bladePrice && Object.entries(bladePrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => bladeValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Screws Price</label>
                    <div class="row">
                    {screwPrice && Object.entries(screwPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => screwValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Aluminium Track Price</label>
                    <div class="row">
                    {alTrackPrice && Object.entries(alTrackPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => alTrackValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Grove Roller Price</label>
                    <div class="row">
                    {gRollPrice && Object.entries(gRollPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => gRollValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Mesh Roller Price</label>
                    <div class="row">
                    {meshRollPrice && Object.entries(meshRollPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => meshRollValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Weather Strip Price</label>
                    <div class="row">
                    {wStripPrice && Object.entries(wStripPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => wStripValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Bump Arrestor Price</label>
                    <div class="row">
                    {bArrestorPrice && Object.entries(bArrestorPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => bArrestorValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Dust Arrestor Price</label>
                    <div class="row">
                    {dArrestorPrice && Object.entries(dArrestorPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => dArrestorValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Jump Stopper Price</label>
                    <div class="row">
                    {jStopperPrice && Object.entries(jStopperPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-3 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => jStopperValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Grill Price</label>
                    <div class="row">
                    {mplPrice && Object.entries(grillPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => grillValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">MPL</label>
                    <div class="row">
                    {mplPrice && Object.entries(mplPrice).map(([key, value], index) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => mplValue(key, e.target.value)}   />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Hinges</label>
                    <div class="row">
                    {hingesPrice && Object.entries(hingesPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => hingesValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Marketing Overheads</label>
                    <div class="row">
                    {marketingPrice && Object.entries(marketingPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => marketingValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Transportation Charges</label>
                    <div class="row">
                    {transportPrice && Object.entries(transportPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => transportValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Labour (Site) Charges</label>
                    <div class="row">
                    {labourSPrice && Object.entries(labourSPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => labourSValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Labour (Factory) Charges</label>
                    <div class="row">
                    {labourFPrice && Object.entries(labourFPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => labourFValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Glass Price</label>
                    <div class="row">
                    {glassPrice && Object.entries(glassPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => glassValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Profit Margin</label>
                    <div class="row">
                    {marginPrice && Object.entries(marginPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => marginValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Handles Changes</label>
                    <div class="row">
                    {handlePrice && Object.entries(handlePrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => handleValue(key, e.target.value)} />
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-6 col-xxl-4 col-xl-4 mt-3">
                    <label htmlFor="name">Board Changes</label>
                    <div class="row">
                    {boardPrice && Object.entries(boardPrice).map(([key, value]) => (
                      <>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={key} readOnly />
                      </div>
                      <div class="col-6 col-md-6 col-xxl-6 col-xl-6">
                        <input type="text" id="name" name="name" class="form-control mt-1" value={value} onChange={(e) => boardValue(key, e.target.value)}/>
                      </div>
                     </>
                    ))}
                    </div>
                    </div>

                    <div class="col-md-12 col-xxl-12 col-xl-12 mt-5" align="center">
                        <button class="btn btn-success w-25" onClick={updateData}>Update Price</button>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    </>
    );
};
    
    export default App;